import {
    tableState,
    tableMutations,
    tableActions,
    tableGetters
} from "@tt/vue-components";

import channelFilter from "@/store/modules/orderImportsOverview/filter/channelFilter.js";
import orderNoFilter from "@/store/modules/orderImportsOverview/filter/orderNoFilter.js";
import emailFilter from "@/store/modules/orderImportsOverview/filter/emailFilter.js";
import validationStatusFilter from "@/store/modules/orderImportsOverview/filter/validationStatusFilter.js";
import trashFilter from "@/store/modules/orderImportsOverview/filter/trashFilter.js";
import validationRuns from "@/store/modules/orderImportsOverview/validationRuns";

import orderService from "@/services/OrderService";

const orderImportsOverview = {
    requestController: null,
    namespaced: true,
    state: {
        ...tableState,
        item: null,
        filters: [],
        defaultFilters: [],
        // sorting: { sortBy: ["createdDt"], sortDesc: [true] },
        pagination: { itemsPerPage: 25, itemsPage: 1 },
    },
    modules: {
        channelFilter,
        emailFilter,
        orderNoFilter,
        validationStatusFilter,
        validationRuns,
        trashFilter
    },
    mutations: {
        ...tableMutations,
        SET_ITEM(state, item) {
            state.item = item
        },
        SET_TOTAL_ITEMS(state, totalItems)
        {
            state.totalItems = totalItems
        }
    },
    actions: {
        ...tableActions,
        statistics(context) {
            const params ={
                pagination: {itemsPerPage: 1, page:1},
                search: [{column: "status", value: "invalid"}, {column: "trash", value: false}]
            };

            orderService.orderImports.list(params)
                .then(json => {
                    if (json) {
                        context.commit("SET_TOTAL_ITEMS", "0")
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        fetchItems(context, refresh = false) {
            if (refresh || context.state.items.length === 0) {
                context.commit("SET_LOADING", true);
                const params = { pagination: context.state.pagination, sorting: context.state.sorting, search: context.state.search };

                if (this.requestController) {
                    this.requestController.abort();
                }
                this.requestController = new AbortController();
                orderService.orderImports.list(params, { signal: this.requestController.signal })
                    .then(json => {
                        if (json) {
                            context.commit("SET_ITEMS", json)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .finally(() => {
                        context.commit("SET_LOADING", false)
                    });
            }
        },
        selectItem({commit, dispatch}, item) {
            commit("SET_ITEM", item);
            if (item !== null && item !== undefined) {
                dispatch("validationRuns/fetchItems", item.id);

            } else {
                dispatch("validationRuns/resetItems");
            }
        },
        // Helper for the child component updates
        updateItem({ commit }, item) {
            commit("EDIT_ITEM", item)
        },
        edit({commit}, orderImport) {
            return new Promise((resolve, reject) => {
                orderService.orderImports.update(orderImport.id, orderImport)
                .then((json) => {
                    commit("EDIT_ITEM", json);
                    resolve();
                })
                .catch(err => {
                    reject(err["hydra:description"]);
                });
            });
        },
        delete({commit}, orderImport) {
            return new Promise((resolve, reject) => {
                orderService.orderImports.delete(orderImport.id)
                    .then(() => {
                        commit("DELETE_ITEM", orderImport.id);
                        resolve();
                    })
                    .catch(err => {
                        reject(err["hydra:description"]);
                    });
            });
        },
        patch({ commit }, orderImport) {
            return new Promise((resolve, reject) => {
                orderService.orderImports.patch(orderImport.id, orderImport.body)
                    .then(json => {
                        if (json) {
                            commit("EDIT_ITEM", json);
                            resolve();
                        }
                    })
                    .catch(err => {
                        reject(err["hydra:description"]);
                    });
            });
        },
        reject({ commit }, orderImport) {
            return new Promise((resolve, reject) => {
                orderService.orderImports.patch(orderImport.id, orderImport.body)
                    .then(json => {
                        if (json) {
                            commit("SET_ITEM", json);
                            commit("EDIT_ITEM", json);
                            resolve();
                        }
                    })
                    .catch(err => {
                        reject(err["hydra:description"]);
                    });
            });
        }
    },
    getters: {
        ...tableGetters,
    }
};

export default orderImportsOverview;
