import Vue from "vue";
import VueRouter from "vue-router";
import { authGuard } from "@tt/vue-auth0";
import i18n from "@/plugins/i18n";
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/orders",
    active: false
  },
  {
    path: "/orders",
    name: "online-orders",
    component: () => import("@/components/orders/OrderOverview.vue"),
    meta: {
      label: i18n.t("orders.overview.title"),
      active: true,
    },
  },
  {
    path: "/pickup-in-store",
    name: "pickup-orders",
    component: () => import("@/components/pickup-orders/Overview.vue"),
    meta: {
      label: i18n.t("pickup.overview.title"),
      active: true,
    },
  },
  {
    path: "/import",
    name: "import",
    component: () => import("@/views/ImportOverview"),
    meta: {
      label: i18n.t("imports.overview.title"),
      active: true,
      badge: {
        content: () => store.state.orderImportsOverview.totalItems ?? "0",
        color: 'primary',
      },
    },
  }
];

// create router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// auth check
router.beforeEach(authGuard);

export default router;
