<template>
  <v-app>
    <AppBar
      v-if="$auth.isAuthenticated"
      color="white"
      :portal-url="portalUrl"
      :buttons="moduleButtons"
      :menu-items="menuItems"
    />

    <v-main>
      <Message />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "@tt/vue-components/src/components/AppBar";
import EnvProvider from "@tt/vue-environment/EnvProvider";

export default {
  name: "App",
  components:  {AppBar} ,
  data: () => ({
    portalUrl: EnvProvider.value("PORTAL_URL"),
    moduleButtons: [
    ]
  }),
  computed: {
    menuItems() {
      return this.$router.options.routes
          .filter(item => {
            return !(item?.meta?.label === undefined);
          })
          .filter(item => {
            return item?.meta?.active === true;
          });
    }
  },
  beforeUpdate() {
    this.$store.dispatch("orderImportsOverview/statistics");
  }
};
</script>
